import { useContext, useEffect, useState } from 'react';
import { Formik, Form, FormikHelpers, validateYupSchema } from 'formik';
import { Link, useParams } from 'react-router-dom';

import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';

import { Header } from '../../../components/Header';
import { Error } from '../../../components/Error';
import { Input } from '../../../components/Input';

import {
  CadastroProducaoFormValues,
  Producao,
  Insumo as OGInsumo,
} from '../../../assets/interfaces/Agricultor';

import { AgricultorContext } from '../../../contexts/AgricultorContext';

import styles from '../../../common/Cadastro.module.scss';

import { Edit, Trash2 } from 'react-feather';

import { server } from '../../../services/api';

import history from '../../../routes/history';
import ProducaoDropdown from './components/dropdown';

import './producaoStyles.scss';

interface Insumo {
  id?: string;
  descricao: string;
  quantidade_utilizada: number; 
  valor_unitario: number;
  valor_total: number;
}

export interface Alimentos {
  count: number;
  results: {
    id: string;
    nome: string;
  }[];
}

export default function CadastroProducao() {
  const { cadastrarProducao, buscarProducaoPorId, alterarProducao, getAlimentos } = useContext(AgricultorContext);

  const [producao, setProducao] = useState<Producao | undefined>();
  const [insumos, setInsumos] = useState<Insumo[]>([]);
  const [insumosCadastrados, setInsumosCadastrados] = useState<OGInsumo[]>([]);
  const [currentInsumoId, setCurrentInsumoId] = useState('');
  const [isEditingInsumo, setIsEditingInsumo] = useState(false);
  const [isEditingProducao, setIsEditingProducao] = useState(false);
  const [producaoOptions, setProducaoOptions] = useState<{ label: string, id: string }[]>([]);
  const [planted, setPlanted] = useState(false);
  const [icOrganica, setIcOrganica] = useState(false);

  let { slug }: any = useParams();
  useEffect(() => {

    const loadAlimentos = async () => {
      const alimentos: Alimentos = await getAlimentos();
      const listaAlimentos: { label: string, id: string }[] = alimentos.results.map((alimento) => {
        return {
          label: alimento.nome,
          id: alimento.id,
        }
      });
      setProducaoOptions(listaAlimentos);
    }

    loadAlimentos();

    const getEdital = async () => {
      const prod = await buscarProducaoPorId(slug);
      setProducao(prod);
      /*const novosInsumos: Insumo[] =
        prod?.listaInsumos?.map((i) => {
          return {
            id: i.insumo.id,
            descricao: i.insumo.descricao,
            quantidade_utilizada: i.insumoProducao.quantidade_utilizada,
            valor_unitario: i.insumo.custo_unitario,
            valor_total: i.insumoProducao.quantidade_utilizada * i.insumo.custo_unitario
          };
        }) || [];
      setInsumos(novosInsumos);*/
    };

    if (slug) {
      getEdital();
      setIsEditingProducao(true);
    }
  }, [buscarProducaoPorId, getAlimentos, slug]);

  useEffect(() => {
    const getInsumos = async () => {
      const { data } = await server.get('/insumos');
      if (data.resultado.insumosCadastrados) {
        setInsumosCadastrados(data.resultado.insumosCadastrados);
      }
    };
    getInsumos();
  }, []);

  async function handleSubmitForm(
    values: CadastroProducaoFormValues,
    actions: FormikHelpers<CadastroProducaoFormValues>
  ) {
    actions.validateForm();


    /*const insumosDaProducao = insumos.map((insumo: Insumo) => {
      return {
        id_insumo: insumo.id || '',
        quantidade_utilizada: insumo.quantidade_utilizada,
        custo:
          insumo.quantidade_utilizada *
          (insumosCadastrados.find((insumo) => insumo.id === values.id_insumo)
            ?.custo_unitario || 1),
      };
    });*/

    if (!slug) {
      await cadastrarProducao({
        id: values.id,
        quantidade_produzida: values.quantidade_produzida,
        area_produzida: values.area_produzida,
        custo_total: values.custo_total,
        //insumosDaProducao: insumosDaProducao,
        nCycle: values.nCycle,
        isPlanted: planted,
        ic_organico: icOrganica
      });
    } else {
      await alterarProducao({
        id: values.id,
        quantidade_produzida: values.quantidade_produzida,
        area_produzida: values.area_produzida,
        custo_total: values.custo_total,
        //insumosDaProducao: insumosDaProducao,
        nCycle: values.nCycle,
        isPlanted: values.isPlanted,
        ic_organico: icOrganica
      });
    }
  }

  function handleAddInsumo(
    insumo: Insumo,
    value: CadastroProducaoFormValues,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    if (insumo.descricao.trim() === '' || insumo.descricao === 'Insumo') {
      return;
    }

    const newInsumo: Insumo = {
      id: insumo.id,
      descricao: insumo.descricao,
      quantidade_utilizada: insumo.quantidade_utilizada,
      valor_unitario: insumo.valor_unitario,
      valor_total: insumo.quantidade_utilizada * insumo.valor_unitario
    };

    setInsumos([...insumos, newInsumo]);

    setFieldValue('insumo', 'Insumo');
    setFieldValue('quantidade_utilizada', 0);
    setFieldValue('custo_total', value.custo_total + newInsumo.valor_total);
  }

  function handleRemoveInsumo(id: string) {
    const filteredInsumos = insumos.filter((insumo) => insumo.id !== id);

    setInsumos(filteredInsumos);
  }

  function handleEditInsumo(
    insumo: Insumo,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    setFieldValue('id_insumo', insumo.id);
    setFieldValue('quantidade_utilizada', insumo.quantidade_utilizada || 0);

    setCurrentInsumoId(insumo.id || '');

    setIsEditingInsumo(true);
  }

  function showValues(
    values: CadastroProducaoFormValues,
  ) {
    console.log(values);
  }

  function handleUpdateInsumo(
    insumo: Insumo,
    value: CadastroProducaoFormValues,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    const editedInsumo =
    {
      id: currentInsumoId,
      descricao: insumo.descricao,
      quantidade_utilizada: insumo.quantidade_utilizada,
      valor_unitario: insumo.valor_unitario,
      valor_total: insumo.valor_unitario * insumo.quantidade_utilizada
    }


    const mappedInsumos = insumos.map((a: Insumo) =>
      a.id === currentInsumoId
        ? editedInsumo
        : a
    );

    function findInsumo() {
      return insumos.some((insumo) => insumo.id === editedInsumo.id);
    }

    if (findInsumo()) {
      setInsumos(mappedInsumos);
    } else {
      handleAddInsumo(insumo, value, setFieldValue);
    }

    setIsEditingInsumo(false);

    setFieldValue('id_insumo', 'Insumo');
    setFieldValue('quantidade_utilizada', 0);
  }

  function handleChangeCheckbox() {
    setPlanted(!planted);
  }

  function handleChangeCheckboxOrganico() {
    setIcOrganica(!icOrganica);
  }

  return (
    <>
      <Header
        titulo={isEditingProducao ? 'Editar produção' : 'Cadastrar produção'}
        descricao='Informe os dados da produção no formulário abaixo.'
      />

      <main className={styles.cadastro}>
        <div className={styles.formCard}>
          <h2>
            {isEditingProducao ? 'Editar produção' : 'Cadastrar produção'}
          </h2>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
          >
            {function ShowForm({
              touched,
              values,
              errors,
              handleChange,
              setFieldValue,
            }) {

              useEffect(() => {
                if (producao) {
                  setFieldValue(
                    'quantidade_produzida',
                    producao.quantidade_produzida
                  );
                  
                  if (producao.alimento) {
                    setFieldValue('id', producao.alimento?.id);
                  }
                  
                  setFieldValue('custo_total', producao.custo_total);
                  setFieldValue('id_insumo', '');
                  setFieldValue('quantidade_utilizada', 0);
                  setFieldValue('ic_organico', producao.ic_organico);
                  setIcOrganica(producao.ic_organico || false);
                  setPlanted(producao.isPlanted)
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
              }, [producao]);

              return (
                <Form>
                  <section>
                    <h3>Dados</h3>

                    <div className={styles.formGroup}>
                      <ProducaoDropdown
                        label="Selecione o Produto"
                        touched={touched}
                        errors={errors}
                        options={producaoOptions}
                        value={values.id}
                        changeCallback={(event: any) => {
                          //console.log(event.value);
                          setProducao({
                            id: values.id,
                            quantidade_produzida: values.quantidade_produzida,
                            custo_total: values.custo_total,
                            area_produzida: values.area_produzida,
                            nCycle: values.nCycle,
                            isPlanted: values.isPlanted
                          });
                          setFieldValue("id", event.value)
                          setFieldValue("produto", event.value.label);
                        }}
                      ></ProducaoDropdown>

                      <Input
                        isRequired
                        label='Quantidade produzida'
                        name='quantidade_produzida'
                        onChange={handleChange}
                        min={0}
                        type='number'
                        value={values.quantidade_produzida}
                      >
                        {errors.quantidade_produzida &&
                          touched.quantidade_produzida ? (
                          <Error text={errors.quantidade_produzida} />
                        ) : null}
                      </Input>
{
  /*
                        <Input
                        isRequired
                        label='Área produzida (m²)'
                        name='area_produzida'
                        onChange={handleChange}
                        min={0}
                        type='number'
                        value={values.area_produzida}
                      >
                        {errors.area_produzida &&
                          touched.area_produzida ? (
                          <Error text={errors.area_produzida} />
                        ) : null}
                      </Input>  

                      <Input
                        isRequired
                        label='Colheitas por ano'
                        name='nCycle'
                        onChange={handleChange}
                        min={0}
                        type='number'
                        value={values.nCycle}
                      >
                        {errors.nCycle &&
                          touched.nCycle ? (
                          <Error text={errors.nCycle} />
                        ) : null}
                      </Input>
  */
}

                      <div className='planted-container'>
                        <label className='planted-label'>Já estou produzindo:</label>
                        <input
                          className='planted-checkbox'
                          type='checkbox'
                          checked={planted}
                          onChange={handleChangeCheckbox}
                        />
                        {errors.isPlanted && touched.isPlanted && (
                          <Error text={errors.isPlanted} />
                        )}
                      </div>

                      <div className='planted-container'>
                        <label className='planted-label'>Produção Orgânica:</label>
                        <input
                          className='planted-checkbox'
                          type='checkbox'
                          checked={icOrganica}
                          onChange={handleChangeCheckboxOrganico}
                        />
                        {errors.isPlanted && touched.isPlanted ? (
                          <Error text={errors.isPlanted} />
                        ) : null}
                      </div>
                    </div>
                  </section>

                  {/*{insumosCadastrados.length > 0 ? (
                    <section>
                      <h3>Insumos</h3>

                      <div className={styles.formGroup}>
                        <div className={styles.selectGroup}>
                          <label htmlFor='id_insumo'>
                            Insumo<span>*</span>
                          </label>

                          <div className={styles.select}>
                            <select
                              name='id_insumo'
                              onChange={handleChange}
                              placeholder='Insumo'
                              value={values.id_insumo}
                            >
                              <option value='Insumo'>Insumo</option>
                              {insumosCadastrados.map((insumo) => {
                                return (
                                  <option value={insumo.id} key={insumo.id}>
                                    {insumo.descricao}
                                  </option>
                                );
                              })}
                            </select>
                            <div className={styles.selectArrow}></div>
                          </div>

                          {errors.id_insumo && touched.id_insumo ? (
                            <Error text={errors.id_insumo} />
                          ) : null}
                        </div>

                        <Input
                          isRequired
                          label='Quantidade utilizada'
                          name='quantidade_utilizada'
                          onChange={handleChange}
                          type='number'
                          min={0}
                          value={values.quantidade_utilizada}
                        >
                          {errors.quantidade_utilizada &&
                            touched.quantidade_utilizada ? (
                            <Error text={errors.quantidade_utilizada} />
                          ) : null}
                        </Input>

                        <span></span>

                        {/*<div className={`${styles.add}`}>
                          {isEditingInsumo ? (
                            <button
                              type='button'
                              onClick={() =>
                                handleUpdateInsumo(
                                  {
                                    descricao: insumosCadastrados.find(
                                      (insumo) => insumo.id === values.id_insumo)?.descricao || '',
                                    quantidade_utilizada: values.quantidade_utilizada,
                                    valor_unitario: insumosCadastrados.find(
                                      (insumo) => insumo.id === values.id_insumo)?.custo_unitario || 0,
                                    valor_total: insumosCadastrados.find(
                                      (insumo) => insumo.id === values.id_insumo)?.custo_unitario || 0
                                      * values.quantidade_utilizada,
                                  },
                                  values,
                                  setFieldValue
                                )
                              }
                            >
                              Editar insumo
                            </button>
                          ) : (
                            <button
                              type='button'
                              onClick={() =>
                                handleAddInsumo(
                                  {
                                    id: insumosCadastrados.find(
                                      (insumo) => insumo.id === values.id_insumo)?.id || 'not_found',
                                    descricao: insumosCadastrados.find(
                                      (insumo) => insumo.id === values.id_insumo)?.descricao || '',
                                    quantidade_utilizada: values.quantidade_utilizada || 0,
                                    valor_unitario: insumosCadastrados.find(
                                      (insumo) => insumo.id === values.id_insumo)?.custo_unitario || 0,
                                    valor_total: insumosCadastrados.find(
                                      (insumo) => insumo.id === values.id_insumo)?.custo_unitario || 0 * values.quantidade_utilizada || 0,
                                  },
                                  values,
                                  setFieldValue
                                )
                              }
                            >
                              Adicionar insumo à lista
                            </button>
                          )}
                            </div>
                      </div>
                    </section>
                  ) : (
                    <p style={{ marginTop: '3rem' }}>
                      É necessário{' '}
                      <Link to='/agricultor/insumo'>cadastrar insumos</Link>{' '}
                      antes de cadastrar uma produção.
                    </p>
                  )}*/}

                  {/*{insumos.length > 0 && (
                    <section className={styles.table}>
                      <table>
                        <thead>
                          <tr>
                            <th scope='col'>Insumo</th>
                            <th scope='col'>Quantidade utilizada</th>
                            <th scope='col'>Valor Unitário</th>
                            <th scope='col'>Valor Total</th>
                            <th scope='col'></th>
                          </tr>
                        </thead>

                        <tbody>
                          {insumos.map((insumo: Insumo) => {
                            return (
                              <tr key={insumo.descricao}>
                                <td data-label='Insumo'>{insumo.descricao}</td>

                                <td data-label='Unidade'>
                                  {insumo.quantidade_utilizada}
                                </td>

                                <td data-label="Valor Unitario">{insumo.valor_unitario}</td>

                                <td data-label="Valor Total">{insumo.valor_total}</td>

                                <td data-label='' className={styles.buttons}>
                                  <button
                                    type='button'
                                    className={`${styles.edit} ${styles.icon}`}
                                    onClick={() =>
                                      handleEditInsumo(insumo, setFieldValue)
                                    }
                                  >
                                    <Edit color='#333333' size='1rem' />
                                  </button>

                                  <button
                                    type='button'
                                    className={`${styles.remove} ${styles.icon}`}
                                    onClick={() =>
                                      handleRemoveInsumo(insumo.id || '')
                                    }
                                  >
                                    <Trash2 color='#EE5253' size='1rem' />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </section>
                  )}*/}
                  <div style={{ marginTop: '3rem', width: '50%' }}>
                    <Input
                      label='Custo total da produção'
                      name='custo_total'
                      onChange={handleChange}
                      min={0}
                      value={values.custo_total}
                    >
                      {errors.custo_total && touched.custo_total ? (
                        <Error text={errors.custo_total} />
                      ) : null}
                    </Input>
                  </div>
                  <section>
                    <div className={styles.formGroup}>
                      <button
                        className={styles.cancel}
                        type='button'
                        onClick={() => history.push('/agricultor/producoes')}
                      >
                        Cancelar
                      </button>

                      <button type='submit' /*disabled={insumos.length === 0}*/>
                        {isEditingProducao
                          ? 'Finalizar edição da produção'
                          : 'Finalizar cadastro da produção'}
                      </button>
                    </div>
                  </section>
                </Form>
              );
            }}
          </Formik>
        </div>
      </main>
      <br />
    </>
  );
}
